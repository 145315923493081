var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "rapidInitiation" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.openDialog,
            title: "快速发起管理",
            width: "900px",
            "before-close": _vm.handleClose,
          },
        },
        [
          _vm.allMenuTreeList
            ? _c("div", { staticClass: "content" }, [
                _c("div", { staticClass: "mainMenu" }, [
                  _c("div", { staticClass: "selected" }, [
                    _c("span", [_vm._v("已选")]),
                    _c("span", { staticClass: "num" }, [_vm._v("(0)")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "mainMenu-list" },
                    [
                      _c(
                        "el-scrollbar",
                        _vm._l(_vm.allMenuTreeList, function (item, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass: "mainMenu-item pointer",
                              class: { active: _vm.mainMenuIndex === index },
                              on: {
                                click: function ($event) {
                                  return _vm.clickMainMenu(index)
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "name" }, [
                                _vm._v(_vm._s(item.meta.title)),
                              ]),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    1
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "subMenu" },
                  [
                    _c("el-scrollbar", { ref: "scrollbar" }, [
                      _c(
                        "div",
                        { staticClass: "subMenu-list" },
                        _vm._l(
                          _vm.allMenuTreeList[_vm.mainMenuIndex].children,
                          function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "subMenu-item" },
                              [
                                _c("div", { staticClass: "subMenuName" }, [
                                  _vm._v(_vm._s(item.meta.title)),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "menu-list" },
                                  _vm._l(
                                    item.children,
                                    function (sonItem, sonIndex) {
                                      return _c(
                                        "div",
                                        {
                                          key: sonIndex,
                                          staticClass: "menu-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "icon" },
                                            [
                                              sonItem.menuImageUrl
                                                ? _c("svg-icon", {
                                                    staticStyle: {
                                                      height: "38px",
                                                      width: "38px",
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        sonItem.menuImageUrl,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkedMenu(
                                                          sonItem
                                                        )
                                                      },
                                                    },
                                                  })
                                                : _c("svg-icon", {
                                                    staticStyle: {
                                                      height: "38px",
                                                      width: "38px",
                                                    },
                                                    attrs: {
                                                      "icon-class":
                                                        "menu-diy-icon",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.checkedMenu(
                                                          sonItem
                                                        )
                                                      },
                                                    },
                                                  }),
                                              _c("el-checkbox", {
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.checkedMenu(
                                                      sonItem,
                                                      "checkbox"
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: sonItem.isSelected,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      sonItem,
                                                      "isSelected",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "sonItem.isSelected",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "name",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.checkedMenu(
                                                    sonItem
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(sonItem.meta.title) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "btn" },
            [
              _c("el-button", { on: { click: _vm.handleClose } }, [
                _vm._v("取消"),
              ]),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.saveShortcutMenu },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }