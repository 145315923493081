var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notice" }, [
    _c("div", { staticClass: "noticeTop" }, [
      _c("div", { staticClass: "backBtn" }, [
        _c(
          "span",
          {
            staticStyle: { display: "flex", width: "65px", cursor: "pointer" },
            on: { click: _vm.returnHome },
          },
          [
            _c("i", { staticClass: "el-icon-arrow-left" }),
            _c("span", [_vm._v("返回")]),
          ]
        ),
      ]),
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        staticClass: "noticeContent",
      },
      [
        _c("el-scrollbar", [
          _c("div", { staticClass: "noticeInfo" }, [
            _c("div", { staticClass: "title" }, [
              _c("pre", {
                staticStyle: { margin: "0 10px" },
                domProps: { innerHTML: _vm._s(_vm.noticeDetail.noticeTitle) },
              }),
            ]),
            _c("pre", {
              staticClass: "content",
              domProps: { innerHTML: _vm._s(_vm.noticeDetail.noticeContent) },
            }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }