<template>
  <!-- 企业公告 -->
  <div class="notice">
    <div class="noticeTop">
      <div class="backBtn">
        <span style="display:flex;width:65px;cursor: pointer;" @click="returnHome">
          <i class="el-icon-arrow-left"></i>
          <span>返回</span>
        </span>
      </div>
    </div>
    <div class="noticeContent" v-loading="loading">
      <el-scrollbar>
        <div class="noticeInfo">
          <div class="title">
            <pre v-html="noticeDetail.noticeTitle" style="margin:0 10px"></pre>
          </div>
          <pre class="content" v-html="noticeDetail.noticeContent"></pre>
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import { getHomeEnterpriseNoticeDetail,getHomeOfficialNoticeDetail } from '@/api/homePage' //接口api
export default {
    name:'Notice',
    props: ['noticeId','curNoticeType'],
    data () {
        return {
            loading:false,//遮罩层
            //公告详情
            noticeDetail:{}
        }
    },
    methods:{
       async getNoticeDetail () {
            this.loading = true
            await this.$nextTick()
            let data = undefined
            try{
              switch (this.curNoticeType) {
                case '0': //产品公告
                {
                  const res = await getHomeOfficialNoticeDetail(this.noticeId)
                  data = res.data
                }
                  break 
                case '1': //企业公告
                {
                  const res = await getHomeEnterpriseNoticeDetail(this.noticeId)
                  data = res.data
                }
                  break
                default:
                  break
              }
              this.noticeDetail = data
              this.loading = false
            }catch(err){
                this.$message.error(err.message)
                this.loading = false
            }
        },
        //返回首页
        returnHome () {
            this.$emit('goHome')
        }
    }
}
</script>

<style lang="scss" scoped>
  //企业公告详情
  .notice {
    height: 100%;
    background-color: #f8f8f8;
    color: #333;
    .noticeTop {
      height: 40px;
      padding: 0 25px;
      background-color: #fff;
      .backBtn {
        height: 40px;
        display: flex;
        justify-content: start;
        align-items: center;
        font-weight: 900;
        .el-icon-arrow-left {
          font-size: 25px;
        }
      }
    }
    .noticeContent {
      padding: 10px 10px 0px;
      min-height: calc(100% - 104px);
      .noticeInfo {
        width: 100%;
        background-color: #fff;
        padding: 0 30px;
        min-height: calc(100vh - 144px);
        .title {
          font-size: 16px;
          font-weight: bold;
          height: 60px;
          line-height: 60px;
        }
        .content {
          font-size: 12px;
          padding-bottom: 20px;
        }
      }
    }
  }
</style>
