<template>
  <!-- 快速发起弹窗 -->
  <div class="rapidInitiation">
    <el-dialog
      :visible="openDialog"
      title="快速发起管理"
      width="900px"
      :before-close="handleClose"
    >
      <div class="content" v-if="allMenuTreeList">
        <!-- 主菜单 (一级菜单) -->
        <div class="mainMenu">
          <div class="selected">
            <span>已选</span>
            <span class="num">(0)</span>
          </div>
          <div class="mainMenu-list">
            <el-scrollbar>
              <div
                class="mainMenu-item pointer"
                :class="{ active: mainMenuIndex === index }"
                v-for="(item, index) in allMenuTreeList"
                :key="index"
                @click="clickMainMenu(index)"
              >
                <span class="name">{{ item.meta.title }}</span>
              </div>
            </el-scrollbar>
          </div>
        </div>
        <!-- 子菜单 (二级菜单三级菜单) -->
        <div class="subMenu">
          <el-scrollbar ref="scrollbar">
            <div class="subMenu-list">
              <div
                class="subMenu-item"
                v-for="(item, index) in allMenuTreeList[mainMenuIndex].children"
                :key="index"
              >
                <div class="subMenuName">{{ item.meta.title }}</div>
                <div class="menu-list">
                  <div
                    class="menu-item"
                    v-for="(sonItem, sonIndex) in item.children"
                    :key="sonIndex"
                  >
                    <div class="icon">
                      <!-- {{ item }} -->
                      <svg-icon
                        v-if="sonItem.menuImageUrl"
                        :icon-class="sonItem.menuImageUrl"
                        style="height: 38px; width: 38px"
                        @click="checkedMenu(sonItem)"
                      />
                      <svg-icon
                        v-else
                        icon-class="menu-diy-icon"
                        style="height: 38px; width: 38px"
                        @click="checkedMenu(sonItem)"
                      />
                      <el-checkbox
                        v-model="sonItem.isSelected"
                        @change="checkedMenu(sonItem, 'checkbox')"
                      ></el-checkbox>
                    </div>
                    <div class="name" @click="checkedMenu(sonItem)">
                      {{ sonItem.meta.title }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="btn">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="saveShortcutMenu">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RapidInitiation",
  props: {
    // 弹窗开关
    openDialog: {
      type: Boolean,
      default: false,
    },
    //全部菜单列表
    allMenuTreeList: {},
  },
  data() {
    return {
      //当前主菜单下标
      mainMenuIndex: 0,
      menuList: [], //选中的菜单
    };
  },
  methods: {
    //弹窗关闭的回调
    handleClose() {
      this.menuList = [];
      this.$emit("update:openDialog", false);
    },
    //点击主菜单
    clickMainMenu(index) {
      this.mainMenuIndex = index;
      this.$refs.scrollbar.wrap.scrollTop = 0;
    },
    //选中的菜单
    checkedMenu(item, isCheckbox) {
      if (!isCheckbox) this.$set(item, "isSelected", !item.isSelected);
      if (this.menuList?.length >= 1)
        this.menuList = this.menuList.filter((x) => x.routerPath !== item.routerPath);
      this.menuList.push({ ...item, isSelected: item.isSelected });
      console.log("选中的菜单", this.menuList);
    },
    //保存选中的菜单
    async saveShortcutMenu() {
      this.$emit("saveShortcutMenu", this.menuList || []);
      this.handleClose();
    },
  },
};
</script>

<style lang="scss" scoped>
.rapidInitiation {
  ::v-deep .el-dialog__body {
    padding: 0;
  }
  //内容区域
  .content {
    height: 500px;
    border-top: 1px solid #f0f0f3;
    border-bottom: 1px solid #f0f0f3;
    display: flex;
    //主菜单(一级菜单)
    .mainMenu {
      flex-grow: 1;
      border-right: 1px solid #f0f0f3;
      .selected {
        line-height: 30px;
        padding: 0 8px;
        font-size: 16px;
        .num {
          padding: 0 5px;
        }
      }

      .mainMenu-list {
        height: 460px;
        margin-top: 10px;
        //滚动条
        .el-scrollbar {
          height: 100%;
          overflow: hidden;
          ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
          }
        }
        .mainMenu-item {
          height: 36px;
          line-height: 36px;
          &:hover {
            background-color: #f2f2f2;
          }
          &.active {
            background-color: #e9f2fd;
            color: #409eff;
          }
          .name {
            padding-left: 48px;
          }
        }
      }
    }
    // 子菜单(二级菜单三级菜单)
    .subMenu {
      width: 70%;
      height: 500px;
      //滚动条
      .el-scrollbar {
        height: 100%;
        overflow: hidden;
        // 侧边滚动条显示
        ::v-deep .el-scrollbar__wrap {
          overflow-x: hidden;
        }
        ::v-deep .el-scrollbar__bar.is-vertical {
          opacity: 1;
        }
      }
      // 二级菜单
      .subMenu-list {
        padding-left: 15px;
        .subMenu-item {
          .subMenuName {
            font-size: 16px;
            line-height: 30px;
          }
          // 三级菜单
          .menu-list {
            display: flex;
            flex-wrap: wrap;
            padding-top: 10px;
            .menu-item {
              text-align: center;
              width: 135px;
              height: 100px;
              cursor: pointer;
              .icon {
                margin: 5px auto;
                position: relative;
                width: 38px;
                height: 38px;
                .el-checkbox {
                  position: absolute;
                  top: -5px;
                  right: -5px;
                }
              }

              .name {
                height: 24px;
              }
            }
          }
        }
      }
    }
  }
  //按钮
  .btn {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
