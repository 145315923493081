import { render, staticRenderFns } from "./notice.vue?vue&type=template&id=e8064516&scoped=true"
import script from "./notice.vue?vue&type=script&lang=js"
export * from "./notice.vue?vue&type=script&lang=js"
import style0 from "./notice.vue?vue&type=style&index=0&id=e8064516&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8064516",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/www/jenkins_workspase/workspace/html-sunyun-saas-erp-application-pre/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e8064516')) {
      api.createRecord('e8064516', component.options)
    } else {
      api.reload('e8064516', component.options)
    }
    module.hot.accept("./notice.vue?vue&type=template&id=e8064516&scoped=true", function () {
      api.rerender('e8064516', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/home-page/components/notice.vue"
export default component.exports